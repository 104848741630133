<template>
  <div>
    <div class="table-page-search-wrapper" v-if="false">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.order_no" placeholder="请输入订单号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="7" :sm="24">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.guid" placeholder="请输入订单编号" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="订购人">
              <a-input v-model="queryParam.buyer_name" placeholder="请输入订购人" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="订购人电话">
              <a-input v-model="queryParam.buyer_telephone" placeholder="请输入电话" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="24">
            <a-form-item label="订购人证件号码">
              <a-input v-model="queryParam.buyer_id_number" placeholder="请输入证件号码" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select
                placeholder="请选择"
                @search="handleTagSearch"
                @change="handleTagSearchChange"
                :default-active-first-option="true"
              >
                <a-select-option v-for="(val,key) in dataSource_tag" :key="key" :tt="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator"v-if="is_add">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>
    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <div style="padding: 0px;" slot-scope="record" slot="expandedRowRender">
        <span>公玥： {{ record.public }}</span>
      </div>
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a v-if="false">删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <create-config-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'

import {
  corporation_config_list,
  corporation_config_delete,
  customs_inventory_push, corporation_config_create
} from '@/api/customs_order'
import CreateConfigForm from '@/views/b-customs_order/modules/CreateConfigForm'

export default {
  name: 'TableList',
  components: {
    CreateConfigForm,
    STable
  },
  data () {
    return {
      tag_value: '',
      is_add: true,
      dataSource_tag: [{ name: '已推送', id: 0 }, { name: '未推送', id: 1 }],
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      showmdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '海关名称',
          dataIndex: 'customs_name',
          width: 300,
          ellipsis: true
        },
        {
          title: '海关编码',
          dataIndex: 'customs_code'
        },
        {
          title: '海关支付收款企业账号',
          dataIndex: 'recp_account'
        },
        {
          title: '收款企业名称',
          dataIndex: 'recp_name',
          width: 300,
          ellipsis: true
        },
        {
          title: '统一社会信用代码',
          dataIndex: 'recp_code'
        },
        {
          title: '证书编号',
          dataIndex: 'cert_no'
        },
        {
          title: '自动提交订单',
          dataIndex: 'auto_push_order',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '自动提交运单',
          dataIndex: 'auto_push_logistics',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '自动推送导入列表',
          dataIndex: 'auto_push_import_list',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return corporation_config_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            this.is_add = data.entries.length < 0
           if (!this.is_add) {
             this.showmdl = data.entries[0]
           } else {
             this.$emit('onEdit', null)
           }
            return data
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries)
    this.dataSource_tag = this.$Dictionaries.is_pushed
  },
  watch: {
    showmdl (val, old) {
      this.$emit('onEdit', val)
    }
  },
  methods: {
    handleTagSearch (value) {
      console.log(value, '98989')
      this.dataSource_tag = value
    },
    handleTagSearchChange (value) {
      this.queryParam.is_pushed = value === '0'
      this.tag_value = this.dataSource_tag[value].name
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handlePush (record) {
      customs_inventory_push(record, record.id).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.$message.success('推送成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleAdd () {
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleWatch (record) {
      console.log(record.id)
      this.$router.push('/e/customs_order/' + record.id + '/list')
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      corporation_config_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          corporation_config_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
