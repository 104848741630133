<template>
  <div>
    <a-form :form="form" v-bind="formLayout" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="DXPID">
            <a-input
              v-decorator="['dxp_id', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="平台企业名称">
            <a-input
              v-decorator="['p_customs_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="平台企业编号">
            <a-input
              v-decorator="['p_customs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="电商企业的海关注册登记名称">
            <a-input
              v-decorator="['customs_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="电商企业的海关注册登记编号">
            <a-input
              v-decorator="['customs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="收款企业名称">
            <a-input
              v-decorator="['recp_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="收款企业统一社会信用编码">
            <a-input
              v-decorator="['recp_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:18, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="海关支付收款企业账号">
            <a-input
              v-decorator="['recp_account', {rules: [{required: true, message: '必填项，请填写信息'}, {max:60, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="申报企业名称">
            <a-input
              v-decorator="['agent_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="申报企业编码">
            <a-input
              v-decorator="['agent_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="仓储企业名称">
            <a-input
              v-decorator="['area_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="仓储企业编码">
            <a-input
              v-decorator="['area_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:18, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="账册编号">
            <a-input
              v-decorator="['ems_no', {rules: [{required: true, message: '必填项，请填写信息'}, {max:30, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="担保企业编码">
            <a-input
              v-decorator="['assure_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:30, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="监管场所">
            <a-input
              v-decorator="['loct_no', {rules: [{required: false, message: '必填项，请填写信息'}, {max:10, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="证书编号">
            <a-input
              v-decorator="['cert_no', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="申报海关编码">
            <a-input
              v-decorator="['customs_declaration_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="口岸编码">
            <a-input
              v-decorator="['port_code', {rules: [{required: false, message: '必填项，请填写信息'}, {max:4, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="是否使用国密证书">
            <a-switch
              v-decorator="['is_sm', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="通知回调地址">
            <a-textarea v-decorator="['callback_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:128, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 1, maxRows: 3 }" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="证书">
            <a-textarea v-decorator="['public', {rules: [{required: true, message: '必填项，请填写信息'}]}]" :rows="15"/>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="私玥">
            <a-textarea v-decorator="['private', {rules: [{required: true, message: '必填项，请填写信息'}]}]" :rows="15" />
            <div style="font-size: 12px; color: gray">  {{ private_tip }} </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="[16,16]">
        <a-col :span="14">
          <a-form-item label="自动提交订单">
            <a-switch
              v-decorator="['auto_push_order', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
        </a-col>
        <a-col :span="10">
          <a-form-item label="自动提交运单">
            <a-switch
              v-decorator="['auto_push_logistics', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
        </a-col>
        <a-col :span="14">
          <a-form-item label="自动提交进口清单">
            <a-switch
              v-decorator="['auto_push_import_list', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
        </a-col>
        <a-col :span="10">
          <a-form-item label="自动提交撤销进口清单">
            <a-switch
              v-decorator="['auto_push_cancel', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
        </a-col>
      </a-row>
      <!--      <a-row :gutter="[16,16]">-->
      <!--        <a-col :span="12">-->
      <!--          <a-form-item label="通知回调地址">-->
      <!--            <a-textarea v-decorator="['callback_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:128, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 1, maxRows: 3 }" />-->
      <!--          </a-form-item>-->
      <!--        </a-col>-->
      <!--      </a-row>-->
      <a-row>
        <a-col :span="14" style="text-align: center">
          <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
        </a-col>
        <a-col :span="10" v-if="false">
          <a-button @click="handleGoBack">返回</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
// import moment from 'moment'
import pick from 'lodash.pick'
import { corporation_config_create, corporation_config_update } from '@/api/customs_order'

export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      formLayout: 'horizontal',
      id: 0,
      private_tip: '备注：私玥内容此处不展示'
    }
  },
  // beforeCreate () {
  //   this.form = this.$form.createForm(this)
  // },
  mounted () {
    this.$nextTick(() => {
      if (this.record !== undefined) {
        this.loadEditInfo(this.record)
      }
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
        if (this.record === undefined || this.record.id === undefined) {
          corporation_config_create(values)
            .then((res) => {
              this.handleGoBack()
              console.log(res, '创建成功')
            })
        } else {
          corporation_config_update(values, this.record.id)
            .then((res) => {
              this.handleGoBack()
              console.log(res, '修改成功')
            })
        }
      })
    },
    handleGetInfo () {

    },
    loadEditInfo (data) {
      console.log('===================', data)
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, ['dxp_id', 'customs_name', 'customs_code', 'p_customs_name', 'p_customs_code', 'recp_name', 'recp_code', 'recp_account', 'cert_no', 'customs_declaration_code', 'port_code',
          'public', 'auto_push_order', 'auto_push_logistics', 'auto_push_import_list', 'auto_push_cancel', 'callback_url', 'ems_no', 'assure_code', 'area_code', 'area_name', 'loct_no', 'agent_name', 'agent_code'])
        console.log('formData', formData)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
