<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    @cancel="() => { $emit('cancel') }"
    @ok="() => { $emit('ok') }"
  >
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[4,4]">
        <a-col :span="24">
          <a-form-item label="海关名称">
            <a-input
              v-decorator="['customs_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="海关编码">
            <a-input
              v-decorator="['customs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:20, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="证书编号">
            <a-input
              v-decorator="['cert_no', {rules: [{required: true, message: '必填项，请填写信息'}, {max:64, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="海关支付收款企业账号">
            <a-input
              v-decorator="['recp_account', {rules: [{required: true, message: '必填项，请填写信息'}, {max:60, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="统一社会信用代码">
            <a-input
              v-decorator="['recp_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:18, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="收款企业名称">
            <a-input
              v-decorator="['recp_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:100, message: '输入超出长度限制'}]}]" />
          </a-form-item>
          <a-form-item label="公玥">
            <a-textarea v-decorator="['public', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="私玥">
            <a-textarea v-decorator="['private', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
          <a-form-item label="回调地址">
            <a-textarea v-decorator="['callback_url', {rules: [{required: true, message: '必填项，请填写信息'}, {max:128, message: '输入超出长度限制'}]}]" :auto-size="{ minRows: 1, maxRows: 3 }" />
          </a-form-item>
          <a-form-item label="自动提交订单">
            <a-switch
              v-decorator="['auto_push_order', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
          <a-form-item label="自动提交运单">
            <a-switch
              v-decorator="['auto_push_logistics', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
          <a-form-item label="自动推送导入列表">
            <a-switch
              v-decorator="['auto_push_import_list', {rules: [{required: false, message: '请输入选择'}],initialValue: false,valuePropName: 'checked'}] ">
            </a-switch>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>

import pick from 'lodash.pick'

const fields = ['customs_name', 'customs_code', 'cert_no', 'recp_account', 'recp_code', 'recp_name', 'public', 'private', 'callback_url']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this)
    }
  },
  created () {
    console.log(this.visible)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
